<template>
  <!-- 订单详情页面 -->
  <div class="positionT0L0">
    <div class="banner">
      <div class="banner_left">
        <div class="line">
          <span class="line_line"></span>
          <h3>基本信息</h3>
        </div>
        <div class="detail"><span>订单编号 : </span>{{ form.orderNo }}</div>
        <div class="img">
          <span>商品封面 : </span>
          <img v-viewer :src="form.coverUrl" alt="图片" />
        </div>
        <div class="detail">
          <span>商品名称 : </span>{{ form.businessName }}
        </div>
        <div class="detail">
          <span>商品类型 : </span>{{ form.businessType }}
        </div>
        <div class="detail"><span>商品金额(元) : </span>{{ form.amount }}</div>
        <div class="detail"><span>订单状态 : </span>{{ form.orderStatus }}</div>
        <div class="detail">
          <span>快递单号 : </span>{{ form.expressNo }}
          <a-icon
            v-show="isShowExpressNo"
            type="edit"
            style="font-size: 20px"
            @click="() => (modalVisible = true)"
          />
        </div>
        <div class="detail"><span>下单时间 : </span>{{ form.createTime }}</div>
      </div>
      <div class="banner_right">
        <div class="line">
          <span class="line_line"></span>
          <h3>支付信息</h3>
        </div>
        <div class="detail"><span>实付金额 (元) : </span>{{ form.price }}</div>
        <div class="detail"><span>支付方式 : </span>{{ form.payType }}</div>
        <div class="detail"><span>支付状态 : </span>{{ form.payStatus }}</div>
        <div class="detail">
          <span>支付时间 : </span
          >{{ form.payTime ? form.payTime : form.createTime }}
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="banner_left">
        <div class="line">
          <span class="line_line"></span>
          <h3>用户信息</h3>
        </div>
        <div class="detail"><span>用户账号 : </span>{{ form.userName }}</div>
        <div class="detail"><span>用户昵称 : </span>{{ form.nickName }}</div>
        <div class="detail"><span>归属地 : </span>{{ form.region }}</div>
      </div>
      <div class="banner_right">
        <div class="line">
          <span class="line_line"></span>
          <h3>第三方信息</h3>
          <a-icon
            v-show="isShowThreeNews"
            type="edit"
            style="font-size: 20px"
            @click="() => (threeNewsVisible = true)"
          />
        </div>
        <div class="detail">
          <span>第三方订单号 : </span>
          {{ form.thirdPartyOrderNo ? form.thirdPartyOrderNo : "——" }}
        </div>
        <div class="detail">
          <span>第三方收件手机号 : </span>
          {{ form.thirdPartyPhone ? form.thirdPartyPhone : "——" }}
        </div>
        <div class="detail">
          <span>第三方渠道 : </span>
          <span v-show="form.thirdPartyChannel == 0"> —— </span>
          <span v-show="form.thirdPartyChannel == 1"> 抖店 </span>
          <span v-show="form.thirdPartyChannel == 2"> 小鹅通 </span>
          <span v-show="form.thirdPartyChannel == 3"> 有赞 </span>
          <span v-show="form.thirdPartyChannel == 4"> 快手 </span>
          <span v-show="form.thirdPartyChannel == 5"> 视频号 </span>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="banner_left">
        <div class="line">
          <span class="line_line"></span>
          <h3>批改服务信息</h3>
        </div>
        <div class="detail" v-show="form.correctFlag == 0">
          <span>开通状态 : </span> 未开通
          <a
            style="margin-left: 10px"
            v-show="
              isShowOpen &&
              form.orderStatus == '已购买' &&
              form.businessType == '课程'
            "
            @click="OpenClick"
          >
            开通
          </a>
        </div>
        <div class="detail" v-show="form.correctFlag == 1">
          <span>开通状态 : </span> 已开通
        </div>
      </div>
    </div>
    <!-- 快递单号弹框 -->
    <EditMedal
      :expressVal="form.expressNo"
      :modalVisible="modalVisible"
      @cancel="() => (modalVisible = false)"
      @ok="ok"
    />
    <!-- 第三方信息弹框 -->
    <ThreeNewsModal
      v-if="threeNewsVisible"
      :modalVisible="threeNewsVisible"
      :form="form"
      @cancel="threeNewsCancel"
      @ok="threeNewsOk"
    />
    <!-- 底部 -->
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button @click="() => $router.go(-1)">返回</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {
  GetOrdersInfoApi,
  GetOrdersExpressNoApi,
  OrderThirdPartyApi,
  OorrectsOpenApi,
} from "@/request/api/orderformManage";
import EditMedal from "./modules/EditMedal";
import ThreeNewsModal from "./modules/ThreeNewsModal.vue";
import { message, Modal } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  mixins: [baseMixin],
  components: { EditMedal, ThreeNewsModal, FooterToolBar },
  created() {
    this.GetOrdersInfoFn();
    if (codeFn("/admin/order/info/saveExpressNo")) this.isShowExpressNo = true;
    if (codeFn("/admin/order/info/third/party")) this.isShowThreeNews = true;
    if (codeFn("/admin/corrects/open")) this.isShowOpen = true;
  },
  data() {
    return {
      modalVisible: false, // 快递模态框是否显示
      threeNewsVisible: false, // 第三方信息模态框是否显示
      currentForm: {},
      form: {
        orderNo: "",
        coverUrl: "",
        businessName: "",
        businessType: "",
        amount: "",
        orderStatus: "",
        expressNo: "",
        createTime: "",
        price: "",
        payType: "",
        payStatus: "",
        payTime: "",
        userName: "",
        nickName: "",
        region: "",
        thirdPartyOrderNo: "",
        thirdPartyPhone: "",
        thirdPartyChannel: "",
        correctFlag: "",
      },
      isShowExpressNo: false, // 快递信息是否隐藏
      isShowOpen: false, // 开通批改服务是否隐藏
      isShowThreeNews: false, // 第三方信息是否隐藏
    };
  },
  methods: {
    // 快递单号模态框的确定按钮
    ok(inpVal) {
      if (inpVal) {
        GetOrdersExpressNoApi({
          orderNo: this.$route.params.orderNo,
          expressNo: inpVal,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("修改成功");
            this.GetOrdersInfoFn();
          }
        });
      }
      this.modalVisible = false;
    },
    // 第三方信息模态框取消按钮
    threeNewsCancel() {
      this.threeNewsVisible = false;
      this.GetOrdersInfoFn();
    },
    // 第三方信息模态框的确定按钮
    threeNewsOk(obj) {
      if (
        obj.thirdPartyOrderNo ||
        obj.thirdPartyPhone ||
        obj.thirdPartyChannel
      ) {
        OrderThirdPartyApi({
          orderNo: this.$route.params.orderNo,
          thirdPartyChannel: obj.thirdPartyChannel,
          thirdPartyOrderNo: obj.thirdPartyOrderNo,
          thirdPartyPhone: obj.thirdPartyPhone,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("设置成功");
            this.GetOrdersInfoFn();
          }
        });
      }
      this.threeNewsVisible = false;
    },
    // 开通的按钮
    OpenClick() {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "是否为该订单(用户)开通批改服务",
        okText: "确认",
        cancelText: "取消",
        width: "500px",
        onOk() {
          let arr = [];
          arr.push({
            courseNo: that.form.businessNo,
            orderNo: that.form.orderNo,
            phone: that.form.userName,
          });
          return new Promise((resolve, reject) => {
            OorrectsOpenApi(arr).then(({ code }) => {
              if (code == 200) {
                message.success("开通成功");
                that.GetOrdersInfoFn();
              }
            });
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => {});
        },
      });
    },
    // 获取订单详情函数
    GetOrdersInfoFn() {
      let orderNo = this.$route.params.orderNo;
      GetOrdersInfoApi({ orderNo: orderNo }).then(({ code, data }) => {
        if (code == 200) this.form = data;
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.banner {
  display: flex;
  margin-bottom: 30px;
  .banner_left {
    flex: 1;
  }
  .banner_right {
    flex: 1;
  }
}
.line {
  position: relative;
  .line_line {
    display: inline-block;
    width: 3px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #409eff;
  }
  h3 {
    margin-left: 10px;
    font-weight: 600;
  }
}
.detail {
  line-height: 40px;
  margin-left: 10px;
  span {
    font-weight: 600;
    margin-right: 10px;
  }
}
.img {
  line-height: 40px;
  margin-left: 10px;
  span {
    font-weight: 600;
  }
  img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
  }
}
.user {
  margin-top: 50px;
}
.line {
  display: flex;
  h3 {
    margin-right: 10px;
  }
}
</style>