<template>
    <!-- 第三方订单列表页面 -->
    <div class="padding24 color_white" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div v-show="$route.meta.isShow">
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.postNo" :loading="tableLoading" :scroll="{ x: 1500 }">
                    <span slot="postImageList" slot-scope="postImageList, record">
                        <span v-viewer>
                            <img style="width:20px;height: 20px;margin-right: 5px;margin-bottom: 5px" v-for="(item,index) in record.postImageList"  class="avatar" slot="postImageList" :src="item"/>
                        </span>
                    </span>
                    <span slot="postContent" slot-scope="postContent, record">
                        <span v-if="!record.postContent">--</span>
                        <a-tooltip placement="bottom" v-else>
                            <template #title><span>{{ record.postContent }}</span></template>
                            <span>{{sizeLength(record.postContent)}}</span>
                        </a-tooltip>
                    </span>
                    <span slot="status" slot-scope="status, record">
                        <span>{{record.status == 1 ? '正常' : record.status == 2  ? '审核中' : '下架'}}</span>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <template v-if="isShoStatus">
                            <a-popconfirm v-if="record.status == 1"  title="确认要下架此话题吗？" ok-text="确定" cancel-text="取消" @confirm="offTheShelf(record)">
                                <a id="copy_memberNo" type="primary">下架</a>
                            </a-popconfirm>
                            <a id="copy_memberNo" v-else-if="record.status == 3" type="primary" @click="putOnTheShelf(record)">上架</a>
                        </template>
                        <a id="copy_memberNo" v-if="record.status == 2"> -- </a>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>

                <!-- 查看图片 -->
                <div v-viewer>
                    <img  v-for="(item,index) in urlImg" :key="index" :src="item" />
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import {GetTopicList,UpdateTopicStatus} from "@/request/api/circleManage";
import MyPagination from "@/components/pagination/MyPagination";
import {message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import { baseMixin } from "@/store/app-mixin";
export default {
    mixins: [baseMixin],
    components: {MyPagination},
    created() {
        this.GetListFn();
        if (codeFn("/adminv2/updateTopicStatus")) this.isShoStatus = true;
    },
    data() {
        return {
            isShoStatus:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,
            visible: false,
            urlImg:[],
            columns: [
                {
                    title: "话题名称",
                    dataIndex: "topicName",
                    key: "topicName",
                    fixed: 'left',
                },
                {
                    title: "内容",
                    dataIndex: "postContent",
                    key: "postContent",
                    scopedSlots: {customRender: "postContent"},
                },
                {
                    title: "图片",
                    dataIndex: "postImageList",
                    key: "postImageList",
                    scopedSlots: {customRender: "postImageList"},
                },
                {
                    title: "发布时间",
                    dataIndex: "showTime",
                    key: "showTime",
                },
                {
                    title: "用户昵称",
                    dataIndex: "nickName",
                    key: "nickName",
                },
                {
                    title: "用户手机号",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "显示状态",
                    dataIndex: "status",
                    key: "status",
                    scopedSlots: {customRender: "status"},
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 80,
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
        };
    },

    methods: {
        // 截取字数
        sizeLength(val){
            if(val.length>10){
                return  val.substring(0,10)+'...'
            }else{
                return val
            }
        },

        //点击查看图片
        imageClick(record){
            this.visible = true
            this.urlImg = record
        },

        // 下架
        offTheShelf(record){
            UpdateTopicStatus({
                postNo:record.postNo,
                status:3
            }).then(({ code, data }) => {
                this.GetListFn()
            });
        },

        // 上架
        putOnTheShelf(record){
            UpdateTopicStatus({
                postNo:record.postNo,
                status:1
            }).then(({ code, data }) => {
                this.GetListFn()
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.GetListFn();
        },

        // 初始化加载
        GetListFn() {
            this.tableLoading = true
            GetTopicList({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                if(code == 200){
                    this.tableLoading = false
                    this.tableData = data.list
                    this.count = data.count
                }else{
                    this.tableLoading = false
                }
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
.visibleStyle{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

}

#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
    /deep/.table-coverImg{
        cursor: pointer;
    }
}
</style>
